import React from "react";
import { graphql, StaticQuery } from "gatsby"
import Layout from "../../layouts/index";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Terms = (props) => {
  const intl = useIntl();
  const terms = props.data.allMarkdownRemark.edges;
  return (
        <Layout className="page-aszf" path={props.location.pathname}>
          <div className="intro">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>Terms</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container pb-6">
            <div className="row">
              {terms.map(edge => (
                <div
                  key={edge.node.frontmatter.path}
                  className="col-12 col-md-4 mb-1"
                >
                  <div className="card service service-teaser">
                    <div className="card-content">
                      <h2>
                        <Link to={edge.node.frontmatter.path}>
                          {edge.node.frontmatter.title}
                        </Link>
                      </h2>
                      <p>{edge.node.excerpt}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Layout>
  );
};

const termsQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/terms/" }
      }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={termsQuery}
    render={data => (
      <Terms location={props.location} props data={data} {...props} />
    )}
  />
)
